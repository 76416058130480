.alerts {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  background: none;
  z-index: 30000;
  .alert {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 25px 77px 25px 25px;
    max-width: 510px;
    background: rgba(51, 51, 51, 1);
    border-radius: 5px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
    align-items: center;
    margin-bottom: 16px;
    margin-right: 16px;
    margin-left: 16px;
    &.danger {
      background: rgba(184, 0, 0, 1); }
    &.success {
      background: rgba(0, 184, 9, 1); }
    .text {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin-right: 35px; }
    .link {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #F98E2D !important;
      text-decoration: none;
      &:hover {
        opacity: .6; } }
    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 14px;
      color: white;
      cursor: pointer;
      background: none;
      border: none;
      &:hover {
        opacity: .6; } } } }
