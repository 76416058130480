.event-calendar {
  display: inline-block;
  padding: 0 1em 1em 1em;
  background-color: $grey-f5;
  border-radius: 0.5em;

  @include media-tablet {
    padding: 0 1.625em 1.625em 1.625em; }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 1em;
    align-items: center; }

  &__title {
    @include fs18;
    padding-top: 0.8889em;
    padding-bottom: 0.8889em;

    @include media-tablet {
      @include h24;
      padding-top: 0.9231;
      padding-bottom: 0.9231;
      font-weight: 400; } }

  &__controls {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5em; }

  &__arrow {
    width: 1.875em;
    height: 2.75em;
    background-image: url("../images/calendar-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;

    &--next {
      transform: rotate(180deg);
      transform-origin: 50% 50%; } }

  &__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: 0.75em;
    column-gap: 0.125em;

    @include media-tablet {
      column-gap: 1em;
      row-gap: 1em; } }

  &__item {
    text-align: center;
    color: $dark-grey;
    border-radius: 4px;
    border: 1px solid $grey-f5;

    &--header {
      @include fs11;
      padding: 0.7276em 0;
      text-transform: uppercase;

      @include media-tablet {
        @include fs14; } }

    &--day {
      @include fs14;
      padding: 0.2143em 0.3571em;
      font-weight: 700;

      @include media-tablet {
        @include fs16;
        padding: 0.3143em 0.3571em; }

      &:hover {
        border: 1px solid $blue; }

      &:nth-of-type(7n),
      &:nth-of-type(7n-1) {
        color: $grey-88;

        &:hover {
          border: 1px solid $grey-f5;
          cursor: default; } } }

    &--today {
      color: $blue; }

    &--active {
      color: #ffffff;
      background-color: $blue;
      border-radius: 0.2857em; }

    &--disabled {
      background-color: none;
      color: $grey-88;
      font-weight: normal; }

    &--today {
      color: $blue; } } }
