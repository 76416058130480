.form {
  display: grid;
  row-gap: 2.5em;

  hr {
    height: 1px;
    margin: 0;

    border: none;
    background-color: $grey-88; }

  &__header {
    display: grid;
    row-gap: 1.125rem; }

  &__body {
    display: grid;
    row-gap: 1.5em; }

  &__group {
    display: grid;
    row-gap: 0.75em;

    &--col-2 {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5em;

      @include max-w(500px) {
        grid-template-columns: 1fr; } }

    &--disclaimer {
      @include p14;

      color: $grey-88; } }

  &__footer {}

  &__buttons {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.75em;

    @include max-w(500px) {
      grid-template-columns: 1fr;
      row-gap: 0.75em; }

    .form__btn {
      width: 100%; } }

  &__autocomplete {
    &-tags {
      display: flex;
      flex-wrap: wrap;

      margin-bottom: -0.75em; }

    &-tag,
    a {
      @include fs14;

      margin-right: 0.8571em;
      padding: 0.1429em 1.4286em 0.1429em 0.4286em;
      margin-bottom: 0.8571em;

      color: $dark-grey;

      background-color: $grey-f5;
      border-radius: 0.2857em;
      background-image: url("../images/autocomplete-close.svg");
      background-repeat: no-repeat;
      background-position: right 6px center;

      &--white {
        @include fs16;

        border: 1px solid $grey-c0;
        background-color: #ffffff; } } }

  &__error {
    &input,
    &select,
    &.input-dd__trigger {
      border-color: $red !important; } }

  // input:invalid,
  // select:invalid
 }  //   border-color: $red !important
