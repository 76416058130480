.text-nowrap {
  white-space: nowrap; }

.text-gray {
  color: $color-gray !important;
  &-88 {
    color: $grey-88 !important; } }

.text-green {
  color: $color-green !important; }

.text-green-footer {
  color: $green-footer !important; }

.text-blue {
  color: $blue !important; }

.text-gray88 {
  color: $grey-88 !important; }

.capitalize {
  &:first-letter {
    text-transform: uppercase; } }

$max: 900;
$step: 100;
@for $i from 1 through ceil($max/$step) {
  $value: ($i * $step);
  .fw-#{$value} {
    font-weight: #{$value}; } }
@for $j from 0 through 9 {
  @for $i from 0 through 99 {
    .fs-#{$j}_#{$i} {
      font-size: #{$j + ($i / 100)}em !important; } } }
