.auth {
  // min-height: 100vh
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 2.5em 0;

  color: $dark-grey;

  background-color: $grey-f5;

  &__wrapper {
    position: relative;

    min-width: 20em;
    max-width: 34em;
    width: 100%;
    padding: 2.5em;

    background-color: #ffffff;
    border-radius: 0.5em; }

  &__close {
    position: absolute;
    top: 3.375em;
    right: 2.625em;

    width: 1.5em;
    height: 1.5em;

    transform: rotate(45deg);

    &::before,
    &::after {
      content: "";

      position: absolute;
      bottom: 0;
      top: 0;
      left: 50%;

      width: 1px;
      background-color: $dark-grey;

 }      // transform-origin: 50% 50%

    &::after {
      transform: rotate(90deg); } }

  h1 {
    @include reset;
    @include h36; }

  &__header {
    &-desc {
      margin-top: 1em; } }

  &__body {
    margin-top: 2.5em; }

  hr {
    height: 1px;
    margin: 0;

    border: none;
    background-color: $grey-88; }

  &__footer {
    margin-top: 1.5em; }

  // input[type="text"],
  // input[type="email"],
  // input[type="password"],
  // select,
  // .auth__dropdown-trigger
  //   +p14

  //   height: 2.7143em
  //   width: 100%
  //   padding: 0 0.8571em

  //   border-radius: 0.2857em
  //   border: 1px solid $grey-e5
  //   appearance: none
  //   user-select: none

  //   &::placeholder
  //     font-family: $base-font

  //     color: $grey-88

  .select-wrapper {
    position: relative;

    &::after {
      content: "";

      width: 10px;
      height: 7px;

      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);

      background-image: url("../images/dropdown-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; } }

  &__dropdown {
    position: relative;

    &--close {
      .auth__dropdown-main {
        height: 0;
        padding: 0;
        overflow: hidden;
        visibility: hidden; } }

    &-trigger {
      position: relative;
      z-index: 10;

      display: flex;
      align-items: center;

      color: $grey-88;

      background-color: #ffffff;
      cursor: pointer;

      &::after {
        @extend .select-wrapper::after; } }

    &-main {
      position: absolute;
      top: 1.875em;
      left: 0;
      right: 0;
      z-index: 5;

      padding: 1.5em;
      max-height: 32em;

      background-color: #ffffff;
      box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.15);
      overflow-y: scroll;

      label {
        @include fs14;

        display: inline-block;
        padding: 0.5714em 0;

        user-select: none;
        cursor: pointer; }

      input:checked + label {
        color: $red; } }

    &-group {
      margin: 0.5em 0;

      &:first-child {
        margin-top: 0; }

      &:last-child {
        margin-bottom: 0; }

      &-title {
        @include fs14;

        margin-bottom: 0.5714em;

        font-weight: 800; } } }

  select {
    // appearance: none
    background-color: transparent;

    &.error {
      outline: none;
      box-shadow: none;

      border-color: $red; } }

  &__socials {
    display: flex;
    align-items: center;

    &-desc {
      @include fs14;

      margin-right: 1.1429em;

      color: $grey-88; }

    &-icons {
      display: flex;
      a {
        margin-right: 0.75em;

        &:last-child {
          margin-right: 0; } } } }

  &__btn {
    min-width: 7.5rem;

    &--red {
      background-color: $blue;
      color: #ffffff; }

    &--grey {
      font-weight: 400;
      background-color: $grey-f5;
      color: $grey-88; } }

  &--login {
    .auth {
      &__footer {
        display: flex;
        justify-content: space-between; } } }

  &--signin {
    .auth {
      &__header {}

      &__body {
        display: grid;
        row-gap: 0.75em;
        margin-top: 1.5em; }

      &__footer {
        display: flex;
        justify-content: space-between; }

      &__email {
        @include fs14;

        padding: 0.5714em 0.9286em;
        min-height: 2.71428em;
        display: flex;
        align-items: center;

        &-desc {
          margin-right: 0.7143em;

          color: $grey-c0; }

        &-item {} }

      &__pwd {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 2em;
        align-items: center;

        &-input {
          position: relative; }

        &-btn {
          position: absolute;
          right: 1em;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;

          width: 1.5em;
          height: 1.5em;

          border: none;
          background-color: transparent;
          cursor: pointer;

          background-image: url("../images/eye-on.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          &--closed {
            background-image: url("../images/eye-off.png"); } }

        &-restore {
          @include fs11;

          grid-row: 1;
          grid-column: 2;

          color: $grey-88;
          text-align: right;

          a {
            color: $red;
            text-decoration: underline;

            &:hover {
              text-decoration: none; } } } } } }

  &--restore {
    .auth {
      &__body {
        margin-top: 1.5em; }

      &__email {
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 0.75em; } } }

  &--reg-complete {
    .auth {
      &__body {
        display: flex;
        justify-content: center;
        margin-top: 1.5em; } } }

  &--pwd-changed {
    .auth {
      &__body {
        display: flex;
        justify-content: center;
        margin-top: 1.5em; } } }

  &--signup {
    .auth {
      &__email {
        @include fs14;

        padding: 0.5714em 0.9286em;
        min-height: 2.71428em;
        display: flex;
        align-items: center;

        &-desc {
          margin-right: 0.7143em;

          color: $grey-c0; } }

      &__pwd {
        &-input {
          position: relative; }

        &-btn {
          position: absolute;
          right: 1em;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;

          width: 1.5em;
          height: 1.5em;

          border: none;
          background-color: transparent;
          cursor: pointer;

          background-image: url("../images/eye-on.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          &--closed {
            background-image: url("../images/eye-off.png"); } } }

      &__captcha {
        padding: 1.625em 1.5em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1.25em;
        align-items: center;

        border-radius: 0.5em;
        background-color: $grey-f5;
        border: 1px solid $grey-e5;

        @include max-w(500px) {
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr;
          row-gap: 1.25em; }

        &-title {
          @include fs11;

          color: $blue;
          margin-bottom: 0.3636em; }

        &-desc {
          @include fs14; }

        &-right {
          display: flex;
          justify-content: space-between; }

        &-item {
          width: 3em;
          height: 3em;

          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          &--hexagon {
            background-image: url("../images/captcha-hexagon.svg");
            &.selected {
              background-image: url("../images/captcha-hexagon-selected.svg"); } }

          &--square {
            background-image: url("../images/captcha-square.svg");
            &.selected {
              background-image: url("../images/captcha-square-selected.svg"); } }

          &--star {
            background-image: url("../images/captcha-star.svg");
            &.selected {
              background-image: url("../images/captcha-star-selected.svg"); } }

          &--circle {
            background-image: url("../images/captcha-circle.svg");
            &.selected {
              background-image: url("../images/captcha-circle-selected.svg"); } }

          &--triangle {
            background-image: url("../images/captcha-triangle.svg");
            &.selected {
              background-image: url("../images/captcha-triangle-selected.svg"); } } } }

      &__privacy {
        padding-top: 1.5em;
        margin-bottom: 1.5em;

        &-checkbox {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          width: 1.3571em;
          height: 1.3571em;

          border-radius: 0.125em;
          border: 1px solid $dark-grey; }

        label {
          @include fs14;

          position: relative;

          padding-right: 2.2857em;
          display: inline-block;
          width: 100%;

          color: $grey-c0;

          cursor: pointer;

          a {
            color: $grey-88;
            text-decoration: underline;

            &:hover {
              text-decoration: none; } } }

        input:checked + .auth__privacy-checkbox {
          background-image: url("../images/privacy-checkbox.svg");
          background-repeat: no-repeat;
          background-position: center;

          border-color: $red;

          transition: none; } } } }

  &__ac {
    position: relative;
    // border: 1px solid $grey-e5

    &::after {
      content: "";

      width: 10px;
      height: 7px;

      position: absolute;
      right: 1em;
      top: 50%;
      transform: translateY(-50%);

      background-image: url("../images/dropdown-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center; }

    &-dropdown {
      @include lreset;

      position: absolute;
      left: 0;
      right: 0;
      z-index: 999;

      display: grid;
      row-gap: 0.4375em;
      height: 0;
      overflow: hidden;

      background-color: #ffffff;
      transition: none; }

    &--open {
      .auth__ac {
        &-input {
          border-bottom: none !important;
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          outline: none; }

        &-dropdown {
          height: auto;
          padding: 0.4375em 0.75em;

          border: 1px solid $grey-e5;
          border-top: 0;
          border-bottom-left-radius: 0.25em;
          border-bottom-right-radius: 0.25em;

          &::before {
            content: "";

            position: absolute;
            top: 0;
            left: 0.75em;
            right: 0.75em;

            height: 1px;
            background-color: $grey-e5; } }

        &-item {
          a {
            @include fs14;

            display: block;
            display: flex;
            align-items: center;
            padding: 0.7857em 0;

            color: $dark-grey; } } } } }

  &--netw {
    .auth {
      &__wrapper {
        display: grid;
        row-gap: 1.5rem; }

      &__header {
        @include h24; }

      &__body {
        margin-top: 0;

        p {
          @include fs14;

          margin: 0.75rem 0;

          span {
            color: $red; }

          &:first-child {
            margin-top: 0; }

          &:last-child {
            margin-bottom: 0; } } }

      &__footer {
        margin-top: 0;
        display: grid;
        row-gap: 1.25rem;

        &-desc {
          @include reset; }

        &-buttons {
          display: flex;
          column-gap: 0.75rem;
          justify-content: space-between;

          .btn {
            max-width: 14.125rem;
            flex-grow: 1; } } } } }

  &--thanks {
    .auth {
      &__wrapper {
        display: grid;
        row-gap: 2.5rem; }

      &__body {
        margin-top: 0;
        display: grid;
        row-gap: 1rem; }

      &__title {
        @include h24; }

      &__desc {
        @include p14;

        a {
          color: $blue; } }

      &__footer {
        margin-top: 0; } } } }
