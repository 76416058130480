$timePeriods: "0800", "0815", "0830", "0845", "0900", "0915", "0930", "0945", "1000", "1015", "1030", "1045", "1100", "1115", "1130", "1145", "1200", "1215", "1230", "1245", "1300", "1315", "1330", "1345", "1400", "1415", "1430", "1445", "1500", "1515", "1530", "1545", "1600", "1615", "1630", "1645", "1700", "1715", "1730", "1745", "1745", "1800", "1815", "1830", "1845", "1900", "1915", "1930", "1945";

$eventDurations: "15", "30", "45", "60", "75", "90", "105", "120";

.calendar {
  display: grid;
  grid-template-areas: ".header" "aside body";
  grid-template-columns: 3.0625em 1fr;
  grid-template-rows: 2em 1fr;
  row-gap: 0.0625em;
  column-gap: 0.0625em;

  &__header {
    position: relative;
    z-index: 10;

    grid-area: header;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.0625em;

    @include media-desktop-1600 {
      grid-template-columns: repeat(6, 1fr); }

    &-item {
      padding: 0 1.7273em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: $grey-ee;
      border-top-left-radius: 1.25em;
      border-top-right-radius: 1.25em;

      &:nth-child(n+5) {
        display: none; }

      @include media-desktop-1600 {
        &:nth-child(n+5) {
          display: flex; } }

      &--today {
        border: 0.0625em solid $dark-grey;
        border-bottom-left-radius: 0.5em;

        .calendar__header-date {
          color: $dark-grey; } }

      &--weekend {
        background-color: $grey-c0;
        color: #ffffff; } }

    &-today {
      @include fs11; }

    &-date {
      @include fs11;

      color: $grey-88;
      font-weight: 800; } }

  &__aside {

    grid-area: aside;
    display: grid;
    grid-template-rows: repeat(13, 6.25em);
    row-gap: 0.0625em;

    background-color: $grey-c0;

    &-item {
      @include fs11;

      padding-top: 1em;

      color: $grey-88;
      font-weight: 800;
      text-align: center;

      background-color: $grey-f5; } }

  &__body {
    position: relative;

    grid-area: body;

    &::before {
      content: "";

      position: absolute;
      left: -0.25em;
      right: -0.25em;
      top: -0.25em;
      bottom: -0.25em;

      background-color: $grey-ee;
      border-radius: 0.5em; } }

  &__week {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.0625em;

    @include media-desktop-1600 {
      grid-template-columns: repeat(6, 1fr); } }

  &__day {
    position: relative;

    &:nth-child(n+5) {
      display: none; }

    @include media-desktop-1600 {
      &:nth-child(n+5) {
        display: block; } }

    &:nth-child(1) {
      .calendar__layout-item {
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em; } }

    &:nth-child(4) {
      .calendar__layout-item {
        border-top-right-radius: 0.5em;
        border-bottom-right-radius: 0.5em; }

      @include media-desktop-1600 {
        .calendar__layout-item {
          border-radius: 0; } } }

    &:last-child {
      @include media-desktop-1600 {
        .calendar__layout-item {
          border-top-right-radius: 0.5em;
          border-bottom-right-radius: 0.5em; } } } }

  &__layout {
    display: grid;
    grid-template-rows: repeat(52, 1.515625em);
    row-gap: 0.0625em;

    &-item {
      background-color: #ffffff;
      grid-row: auto / span 4; } }

  &__events {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-rows: repeat(52, 1.515625em);
    row-gap: 0.0625em; }

  &__item {
    position: relative;

    border-radius: 0.375em;
    margin: 0.125em;
    padding: 0.5em 0.75em;

    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top 0.5em  right 0.75em;

    &::before {
      content: "";

      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0; }

    &-content {
      position: relative; }

    &-title {
      @include fs11;

      font-weight: 800; }

    &-desc {
      @include fs11;

      margin-top: 0.3636em; }

    &--meet-request {
      border: 0.0625em solid $lightblue;
      background-image: url("../images/calendar-request.svg");

      &::before {
        background-color: rgba($lightblue, 0.05); }

      .calendar__item {
        &-title {
          color: $lightblue; } } }

    &--more-requests {
      background-color: $lightblue;
      justify-content: flex-end;

      .calendar__item {
        &-title {
          color: #ffffff; } } }

    &--waiting {
      border: 0.0625em solid $grey-c0;
      background-color: $grey-ee;
      background-image: url("../images/calendar-waiting.svg"); }

    &[data-event-duration="15"] {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      background-position: center  right 0.75em;

      .calendar__item {
        &-desc {
          display: none; } } }

    &--broadcast {
      border: 1px solid $yellow-calendar;

      background-image: url("../images/calendar-broadcast.svg");

      .calendar__item {
        &-title {
          color: $grey-88; } } }

    &--webinar {
      background-image: url("../images/calendar-broadcast.svg"); }

    &--confirmed {
      border: 1px solid $green-calendar;
      background-image: url("../images/calendar-approved.svg");

      &::before {
        background-color: rgba($green-calendar, 0.05); }

      .calendar__item {
        &-title {
          color: $green-calendar; } } }

    @each $period in $timePeriods {
      $i: index($timePeriods, $period);
      &[data-event-start="#{$period}"] {
        grid-row-start: $i; } }

    @each $duration in $eventDurations {
      $i: index($eventDurations, $duration);
      &[data-event-duration="#{$duration}"] {
        grid-row-end: span $i; } } } }
