.page-event-list {
  align-self: center;
  width: 100%;
  // max-width: calc(#{$header-inner-width} + 5em)
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    h1 {
      margin: 0;
      padding: 0;
      font-size: 2.25em;
      line-height: 1.2;
      font-weight: 800; }
    a {
      @include fs14;
      font-weight: normal;
      display: flex;
      align-items: center;
      padding: 1em 1.4286em;
      margin-left: auto;
      color: $grey-88;
      border-radius: 0.2857em;
      background-color: #ffffff;
      box-shadow: 0.2857em 0.2857em 0.8571em rgba(0, 0, 0, 0.15);
      svg {
        margin-right: .5em; } } }
  nav {
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    .spacer {
      flex-grow: 1; }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style-type: none;
      li {
        margin: 0;
        padding: 0;
        a {
          border-radius: 8px 8px 0px 0px;
          width: 11.857em;
          display: flex;
          padding: 8px 20px;
          background: #EEEEEE;
          color: #888888;
          font-size: 14px;
          svg {
            margin-left: .5em; }
          &.active {
            color: #191919;
            background: white;
            font-weight: 800; }
          &.red {
            background: #FF0011;
            font-weight: 800;
            color: white;
            width: 13.78em; } } } } }

  .data {
    min-height: 100px;
    display: flex;
    line-height: 120%;
    flex-grow: 1;
    background: white;
    border-radius: 0 0 8px 8px;
    flex-direction: column;
    .card {
      border-bottom: 1px solid #E5E5E5;
      display: grid;
      grid-template-columns: repeat(3, 1fr) 12.5rem;
      padding: 2em;
      .block {
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        border-left: 1px solid #E5E5E5;

        &--grid {
          display: grid; }

        &:first-child {
          border: none;
          padding-left: 0; }
        &:last-child {
          padding-right: 0; }
        .spacer {
          flex-grow: 1; }
        .username {
          font-weight: 800;
          font-size: 18px;
          line-height: 125%; }
        .plus {
          font-size: 10px;
          font-weight: 1000;
          color: #FF0011; }
        .smalltext {
          color: #888888;
          font-size: 11px;
          line-height: 135%; }
        .description {
          font-size: 14px;
          line-height: 130%;
          color: #191919;
          margin-top: 8px; }
        .userblock {
          margin-top: 8px;
          .name {
            font-weight: 800;
            font-size: 14px;
            line-height: 130%; }
          .position {
            font-size: 11px;
            line-height: 135%;
            color: #191919;
            margin-top: 4px; } }
        .chat-btn {
          @include fs11;

          color: #191919;
          padding: 4px 12px;
          margin-top: 16px;
          border-radius: 4px;
          display: flex;
          width: fit-content;
          background: $grey-f5;
          border-radius: 2rem; }

        .change-time-link {
          margin-top: 16px;
          font-size: 14px;
          line-height: 130%;
          color: #191919; } }

      &:last-child {
        border: none; }
      &.disabled {
        opacity: .7;
        &:last-child {
          border: none; } } } } }
