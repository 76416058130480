.secret {
  padding: 2.5rem 0;

  background-color: $color-yellow-2;

  @include media-desktop {
    padding: 5rem 0; }

  &__block {
    display: grid;

    border-radius: 1.25rem;
    overflow: hidden;

    background-color: #ffffff;

    @include media-tablet-h {
      grid-template-columns: repeat(2, 1fr); } }

  &__img {
    height: 10.1875rem;

    @include media-tablet {
      height: 16.875rem; }

    @include media-tablet-h {
      height: 100%; }

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center; } }

  &__content {
    padding: 1.5rem;
    display: grid;
    row-gap: 1.5rem;

    @include media-tablet-h {
      padding: 2.5rem; } }

  &__tag {
    @include fs18;

    display: inline-block;
    padding: 0.3125rem 0.5625rem;

    background-color: $grey-f5;
    border-radius: 2rem; }

  &__body {
    display: grid;
    row-gap: 0.75rem; }

  &__title {
    @include fs18;

    font-weight: 800;

    @include media-tablet {
      @include h36; } }

  &__desc {
    @include fs18;

    @include media-tablet-h {
      @include p18; } }

  &__footer {
    display: grid;
    row-gap: 1.5rem;

    @include media-desktop {
      grid-template-columns: 1fr auto;
      column-gap: 1.5rem;
      align-items: center; } }

  &__btn {
    width: 100%;

    padding-left: 1.5rem;
    padding-right: 1.5rem; }

  &__disclaimer {
    @include fs14;

    color: #000; } }
