.meeting {
  display: grid;
  max-width: 1520px;
  margin: 0 auto;
  margin-bottom: 1em;
  font-size: 16px;
  @include media-tablet {
    grid-gap: 40px 32px; }
  @include media-desktop {
    grid-template-columns: 1fr 378px; }
  @include media-desktop-1440 {
    grid-template-columns: 1fr 431px; }
  @include media-desktop-1600 {
    grid-template-columns: 1fr 484px; }
  h2 {
    font-size: 24px;
    font-weight: 800;
    line-height: 1.25; }
  h3 {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 800;
    line-height: 1.3;
    @include media-tablet {
      font-size: 24px; } }
  b {
    font-weight: 800; }
  hr {
    height: 1px;
    margin: 0;
    border: none;
    background: $grey-c4; }
  &__event {
    display: grid;
    grid-gap: 24px;
    padding: 24px;
    line-height: 1.25;
    background: #fff;
    border-radius: 8px;
    @include media-tablet {
      grid-template-columns: 124px 1px 1fr;
      grid-gap: 32px; }
    @include media-desktop {
      grid-row-start: 1;
      grid-column: 1 / span 2; }
    hr {
      @include media-tablet {
        height: 100%; } }
    &-type {
      margin: 0;
      font-size: 14px;
      font-weight: 800;
      color: $blue;
      @include media-tablet {
        font-size: 18px; } }
    &-date {
      display: grid;
      grid-gap: 4px;
      font-size: 14px;
      @include media-tablet {
        grid-gap: 8px;
        font-size: 16px; } }
    &-info {
      display: grid;
      grid-gap: 8px;
      @include media-tablet {
        grid-template-rows: 1fr auto; } }
    &-participants {
      display: grid;
      grid-gap: 8px;
      font-size: 16px;
      font-weight: 800;
      @include media-tablet {
        font-size: 24px; }
      p {
        margin: 0; }
      figure {
        margin: 0;
        font-size: 0;
        img {
          width: 15px;
          height: 15px;
          object-position: center;
          object-fit: scale-down; } } } }
  &__participant {
    display: grid;
    grid-gap: 16px;
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    @include media-tablet {
      grid-template-rows: 120px 1px 1fr;
      align-items: start; }
    &-name {
      font-size: 14px;
      font-weight: 800; }
    &-logo {
      display: block;
      width: 80px;
      height: 50px;
      margin-bottom: 16px;
      padding: 6px;
      border: $grey-e5 1px solid;
      border-radius: 4px;
      object-position: center;
      object-fit: scale-down; }
    &-hint {
      font-size: 11px;
      color: $grey-88; }
    &s {
      margin-top: 58px;
      display: grid;
      grid-gap: 16px;
      @include media-tablet {
        margin: 0;
        grid-template-columns: repeat(2, 1fr); }
      &-title {
        margin-bottom: 8px;
        @include media-tablet {
          grid-column: 1 / span 2; } }
      &-add {
        @include media-tablet {
          grid-column: 1 / span 2; }
        .btn {
          width: 100%; } } } }
  &__expert {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 4px 8px;
    &s {
      display: grid;
      grid-gap: 12px; }
    &-photo {
      grid-row: 1 / span 2;
      overflow: hidden;
      width: 24px;
      height: 24px;
      margin: 0;
      border-radius: 50%;
      background-color: #f5f5f5f5;
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover; } }
    &-name {
      font-size: 14px; }
    &-position {
      font-size: 11px;
      color: $grey-88; } }
  &__stream {
    overflow: hidden;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100vw;
    height: 156vw;
    margin: 58px -1.25em 0;
    background: $grey-e5;
    border-radius: 8px 8px 0 0;
    @include media-tablet {
      width: 100%;
      height: 125vw;
      margin: 0;
      border-radius: 8px; }
    @include media-tablet-h {
      height: 43.26vw; }
    @include media-desktop {
      height: 395px;
      width: 789px; }
    @include media-desktop-1440 {
      height: 438px;
      width: 896px; }
    @include media-desktop-1600 {
      height: 481px;
      width: 1003px; }
    .popup {
      grid-column-start: 1;
      grid-row-start: 1;
      padding: 40px;
      font-size: 18px;
      line-height: 1.5;
      background: #fff;
      border-radius: 8px;
      z-index: 2;
      p {
        margin: 0; }
      hr {
        background: $grey-e5; }
      &__grid {
        display: grid;
        grid-gap: 16px 8px;
        &_2 {
          @extend .popup__grid;
          @include media-tablet {
            grid-template-columns: repeat(2, auto); } } }
      &__card {
        display: grid;
        grid-gap: 4px 12px;
        grid-template-columns: auto 1fr;
        max-width: 255px;
        line-height: 1.3;
        &-hint {
          grid-column: 1 / span 2;
          padding-bottom: 8px;
          font-size: 11px;
          color: $grey-88; }
        &-photo {
          grid-row: 2 / span 2;
          overflow: hidden;
          width: 40px;
          height: 40px;
          object-position: center;
          object-fit: cover;
          border-radius: 50%;
          background-color: #f5f5f5f5; }
        &-name {
          font-size: 14px;
          font-weight: bold; }
        &-position {
          font-size: 11px; } }
      .btn {
        width: 100%; }
      .small {
        font-size: .78em; } }
    .video {
      grid-column-start: 1;
      grid-row-start: 1;
      width: 100%;
      height: 100%;
      background: $dark-grey; } }
  &__chat {
    display: grid;
    grid-template-rows: auto 443px auto;
    width: 100vw;
    margin: 0 -1.25em;
    padding: 8px 0;
    background: #fff;
    @include media-tablet {
      width: 100%;
      margin: 0;
      border-radius: 8px; }
    @include media-tablet-h {
      max-height: 43.26vw; }
    @include media-desktop {
      grid-template-rows: auto minmax(443px, 1fr) auto;
      grid-row: 2 / span 2;
      grid-column-start: 2;
      max-height: 395px; }
    @include media-desktop-1440 {
      max-height: 438px; }
    @include media-desktop-1600 {
      max-height: 481px; }
    .form {
      input {
        width: 100%;
        height: 40px;
        padding: 0 50px 0 12px;
        font-family: inherit;
        font-size: 14px;
        line-height: 40px;
        border: none;
        background: $grey-f5;
        border-radius: 8px; }
      ::-webkit-input-placeholder {
        color: $grey-c0; }
      ::-moz-placeholder {
        color: $grey-c0; }
      &__group {
        position: relative;
        margin: 16px 0;
        button {
          width: 50px;
          height: 40px;
          position: absolute;
          right: 0;
          top: 0;
          padding: 10px 15px;
          background: none;
          border: none;
          img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: scale-down; } } } }
    &-header {
      padding: 0 1.25em; }
    &-body {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 12px 1.25em;
      border-top: $grey-e5 1px solid;
      border-bottom: $grey-e5 1px solid; }
    &-footer {
      padding: 20px 1.25em 0; }
    &-btn {
      display: inline-flex;
      align-items: center;
      padding: 7px;
      margin-right: 8px;
      font-size: 11px;
      line-height: 1.1;
      color: $blue;
      border: $blue 1px solid;
      border-radius: 4px; }
    &-icon {
      width: 11px;
      height: 11px;
      margin-left: 5px;
      object-position: center;
      object-fit: scale-down; } }
  &__card {
    display: grid;
    grid-gap: 4px;
    &-name {
      font-size: 16px;
      line-height: 1.3; }
    &-position {
      font-size: 11px;
      line-height: 1.35;
      color: $grey-88; }
    &-contact {
      font-size: 11px;
      line-height: 1.35; }
    &-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: 5px;
      font-size: 11px;
      line-height: 15px;
      a {
        margin-left: 10px; } } }
  &__message {
    max-width: 255px;
    position: relative;
    margin: 4px 0;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.3;
    background: $grey-f5;
    &:after {
      content: '';
      display: block;
      width: 22px;
      height: 15px;
      position: absolute;
      left: -6px;
      bottom: 0;
      background: url(../images/chat-message-tale.svg) left bottom no-repeat; }
    &_self {
      max-width: 255px;
      position: relative;
      margin: 4px 0 4px auto;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1.3;
      background: $grey-f5;
      &:after {
        content: '';
        display: block;
        width: 22px;
        height: 15px;
        position: absolute;
        right: -6px;
        bottom: 0;
        background: url(../images/chat-message-tale.svg) left bottom no-repeat;
        transform: rotateY(180deg); } } }
  &__author {
    display: flex;
    align-items: center;
    margin: 8px 0 21px;
    font-size: 11px;
    line-height: 15px;
    &-photo {
      overflow: hidden;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: #f5f5f5f5; }
    &-date {
      margin: 0 12px;
      color: $grey-c0; } }
  &__me {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 0 21px;
    font-size: 11px;
    line-height: 15px;
    &-photo {
      overflow: hidden;
      width: 15px;
      height: 15px;
      margin-right: 4px;
      border-radius: 50%;
      background-color: #f5f5f5f5; }
    &-name {
      font-size: 14px; }
    &-date {
      margin: 0 12px;
      color: $grey-c0; } }
  &__services {
    margin: 24px 0;
    font-size: 11px;
    line-height: 1.35;
    text-align: center;
    color: $grey-92;
    a {
      text-decoration: underline;
      color: inherit; } } }
