.stand-menu {
  display: grid;
  row-gap: 1.5em;
  padding: 1.5em;

  @include media-desktop {
    grid-template-columns: 1fr max-content;
    align-items: center; }

  &__otm {
    align-items: center;
    border-radius: 12px;
    color: #ffffff;
    padding: 0.5em 0.75em;
    background-color: $color-yellow-new;

    @include media-tablet {
      border-radius: 33px; }

    &-logo {
      width: 2.5em;
      height: 1.5em;

      img {
        @include contain-img; } }

    &-title {
      @include fs14;
      color: $color-black;

      span {
        text-transform: uppercase; } } }

  &__menu {
    @include lreset;
    display: grid;
    row-gap: 1.25em;

    @include media-tablet {
      grid-row: 1;
      display: flex;
      justify-content: space-between; }

    @include media-desktop {
      justify-content: start;
      grid-column: 1; }

    li {
      margin-right: 4em;

      &:last-of-type {
        margin-right: 0; } }

    a {
      color: $dark-grey;

      &.stand-menu__back-link {
        position: relative;
        padding-left: 1.25em;
        color: $blue;
        font-weight: 800;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1em;
          height: 1em;
          background-image: url("../images/stand-menu-back.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center; } } } } }
