$color-gray: #E4E4E4;
$color-orange: #F8A332;
$color-green: #4ECD00;
$color-red: #FF0011;
$color-lightgray: #F6F6F6;
$color-dark: #333333;
$color-black: #000222;
$color-yellow: #FFD600;
$color-yellow-new: #FFEE00;
$color-yellow-2: #EEDD00;

$red: #ff0011;
$red2: #ee0000;
$dark-grey: #191919;
$light-grey: #F8F8F8;
$grey: #8f8f8f;
$green: #006633;
$grey-ee: #eeeeee;
$grey-f5: #f5f5f5;
$grey-e5: #e5e5e5;
$grey-88: #888888;
$grey-89: #888999;
$grey-80: #808080;
$grey-22: #222222;
$grey-f0: #f0f0f0;
$yellow: #F3F71D;
$grey-c0: #C0C0C0;
$grey-c4: #c4c4c4;
$grey-82: #828282;
$grey-92: #929292;
$lightblue: #55CCFF;
$yellow-calendar: #FFCC44;
$green-calendar: #66CC99;
$green-footer: #8ed101;
$blue: #185ADB;
