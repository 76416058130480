.stand {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.25em;

  color: $dark-grey;

  background-color: $grey-f5;

  &--free {
    .stand__grid {
      row-gap: 2.5em;

      @include media-desktop {
        row-gap: 2em; } }

    .stand__body,
    .stand__right-col,
    .stand__left-col {
      row-gap: 2.5em;

      @include media-tablet {
        row-gap: 4em; }

      @include media-desktop {
        row-gap: 2em; } } }

  &--general {
    position: relative;

    padding-top: 28.75em;

    @include media-desktop {
      padding-top: 45.5208vw; }

    &.stand--no-mobile {
      padding-top: 1.25em;

      @include media-desktop {
        padding-top: 45.5208vw; } }

    &.stand--no-desktop {
      padding-top: 28.75em;

      @include media-desktop {
        padding-top: 1.25em; } }

    &.stand--no-mobile.stand--no-desktop {
      padding-top: 1.25em; } }

  &__block {
    position: relative;

    padding: 1.5em;

    background-color: #ffffff;
    border-radius: 1.25em;

    @include media-desktop {
      padding: 2.5em; } }

  &__grid {
    width: 100%;
    display: grid;
    row-gap: 2.5em;

    max-width: 95em;
    margin: 0 auto; }

  &__header {
    display: grid;
    row-gap: 2.5em; }

  &__body {
    display: grid;
    row-gap: 2.5em;

    @include media-tablet {
      row-gap: 4em; }

    @include media-desktop {
      grid-template-columns: 1fr 31.5%;
      column-gap: 2em; } }

  &__left-col {
    grid-row: 2;

    @include media-desktop {
      grid-row: 1; } }

  &__right-col {
    grid-row: 1;
    grid-auto-rows: max-content; }

  &__left-col,
  &__right-col {

    display: grid;
    row-gap: 2.5em;

    align-items: start;

    @include media-tablet {
      row-gap: 4em; } } }
