// heading mixins

@mixin h96 {
  font-size: 6em;
  line-height: 0.9;
  font-weight: 800; }

@mixin h64 {
  font-size: 4em;
  line-height: 1;
  font-weight: 800; }

@mixin h48 {
  font-size: 3em;
  line-height: 1.1;
  font-weight: 800; }

@mixin h36 {
  font-size: 2.25em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h24 {
  font-size: 1.5em;
  line-height: 1.25;
  font-weight: 800; }

@mixin h18 {
  font-size: 1.125em;
  line-height: 1.1;
  font-weight: 800; }

@mixin h16 {
  font-size: 1em;
  line-height: 1;
  font-weight: 800; }

// regular blocks mixins

@mixin fs24 {
  font-size: 1.5em;
  line-height: 1.25; }

@mixin fs18 {
  font-size: 1.125em;
  line-height: 1.25; }

@mixin fs16 {
  font-size: 1em;
  line-height: 1.3; }

@mixin fs14 {
  font-size: 0.875em;
  line-height: 1.3; }

@mixin fs11 {
  font-size: 0.6875em;
  line-height: 1.35; }

// paragraph mixins

@mixin p18 {
  font-size: 1.125em;
  line-height: 1.5; }

@mixin p16 {
  font-size: 1em;
  line-height: 1.55; }

@mixin p14 {
  font-size: 0.875em;
  line-height: 1.55; }

@mixin p11 {
  font-size: 0.6875em;
  line-height: 1.6; }
