.header--index {
  display: block;
  height: auto;
  background: $grey-e5;
  z-index: 0;

  @include media-tablet {
    background: $grey-e5; }

  @include media-desktop-1600 {
    background: $color-yellow-new;
    padding-left: 5em; }

  .header {
    &__main {
      position: relative;
      padding: 0 1.25em;

      @include media-tablet {
        display: grid;
        height: 39.0625em;
        padding: 0; }

      @include media-desktop {
        display: grid;
        grid-template-columns: 29.6875em 1fr;
        height: 40.625em; }

      @include media-desktop-1440 {
        grid-template-columns: 31.75em 1fr;
        height: 45.625em; }

      @include media-desktop-1600 {
        position: relative;
        grid-template-columns: 26.5em 1fr;
        width: 100em;
        height: 45.625em;
        margin-left: auto; }

      @include media-desktop-1920 {
        width: 105em; }

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 36.5625em;
        height: 40.625em;
        background: url(@public/images/header/main-left-before-desktop.svg) no-repeat;
        z-index: 1;

        @include media-tablet {
          display: none; } }

      &-left {
        position: relative;
        padding-top: 3.8125em;
        z-index: 10;

        @include media-tablet {
          display: flex;
          flex-direction: column;
          padding-top: 5.125em;
          padding-left: 2em; }

        @include media-tablet-h {
          order: initial;
          margin-top: 0;
          padding-top: 3.5em;
          padding-left: 1.875em; }

        @include media-desktop {
          padding-top: 4em;
          padding-left: 5em; }

        @include media-desktop-1440 {
          padding-top: 5.5em; }

        @include media-desktop-1600 {
          padding-left: 0; }

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          display: none;
          width: 44.625em;
          height: 39.0625em;
          background: url(@public/images/header/main-left-before-tablet.svg) no-repeat;
          z-index: 10;

          @include media-tablet {
            display: block; }

          @include media-tablet-h {
            width: 45.1875em;
            background: url(@public/images/header/main-left-before-tablet-h.svg) no-repeat; }

          @include media-desktop {
            width: 36.5625em;
            height: 40.625em;
            background: url(@public/images/header/main-left-before-desktop.svg) no-repeat;
            z-index: 20; }

          @include media-desktop-1440 {
            width: 39.5em;
            height: 45.625em;
            background: url(@public/images/header/main-left-before-desktop-1440.svg) no-repeat; }

          @include media-desktop-1600 {
            left: -5rem; } }

        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 28.9375em;
          display: none;
          width: 36.4375em;
          height: 32.6875em;
          background: url(@public/images/header/main-left-after-tablet.svg) no-repeat;
          z-index: 20;

          @include media-tablet {
            display: block; }

          @include media-tablet-h {
            left: 33.5625em;
            width: 46.25em;
            height: 36em;
            background: url(@public/images/header/main-left-after-tablet-h.svg) no-repeat; }

          @include media-desktop {
            left: 21.875em;
            width: 32.1875em;
            height: 20.625em;
            background: url(@public/images/header/main-left-after-desktop.svg) no-repeat;
            z-index: 10; }

          @include media-desktop-1440 {
            left: 24.875em;
            width: 34.3125em;
            height: 23.9375em;
            background: url(@public/images/header/main-left-after-desktop-1440.svg) no-repeat; }

          @include media-desktop-1600 {
            left: 21em;
            width: 36.375em;
            height: 25.8125em;
            background: url(@public/images/header/main-left-after-desktop-1600.svg) no-repeat; }

          @include media-desktop-1920 {
            left: 16em;
            width: 41.5625em;
            height: 30.125em;
            background: url(@public/images/header/main-left-after-desktop-1920.svg) no-repeat; } }

        &-top {
          margin-bottom: 2em;

          @include media-tablet {
            margin-bottom: 2.25em;
            z-index: 50; }

          @include media-tablet-h {
            margin-bottom: 2.5em; } }

        &-date {
          @include fs18;
          margin-bottom: 0.25rem;
          font-weight: 700;
          color: $color-black;

          @include media-tablet {
            @include h24;
            margin-bottom: 0; } }

        &-logo {
          margin-bottom: 0.25rem;

          @include media-tablet {
            margin-bottom: 1.25em; }

          img {
            display: block;
            width: 17.375em;

            @include media-tablet {
              width: 21.625em;
              max-width: none; }

            @include media-desktop {
              width: 23.75em; }

            @include media-desktop-1440 {
              width: 27.5em; } } }

        &-text {
          @include h36;
          width: 21.625rem;
          text-transform: uppercase;
          color: $color-black;

          @include media-tablet {
            @include h48; } }

        &-bottom {
          @include media-tablet {
            width: 24.375em;
            z-index: 50; }

          @include media-tablet-h {
            margin: 0;
            width: 100%; } }

        &-desc {
          @include fs24;
          display: none;
          margin-bottom: 2.5rem;
          color: $color-black;

          @include media-tablet {
            display: block;
            margin-bottom: 2.25rem; }

          @include media-tablet-h {
            width: 25.75rem;
            margin-bottom: 2.5rem; }

          @include media-desktop-1440 {
            width: 27.5rem; } }

        &-reg {
          .btn {
            @include h24;
            width: 17.375rem;
            min-width: auto;
            height: 3.875rem;

            @include media-tablet {
              width: 100%; }

            @include media-tablet-h {
              width: 23.6875rem; }

            @include media-desktop-1440 {
              width: 27.5rem; } } } }

      &-right {
        position: relative;
        height: 10.8125em;

        @include media-tablet {
          display: none; }

        @include media-desktop {
          display: block;
          height: 100%;
          overflow: hidden; }

        @include media-desktop-1600 {
          background: #fff; }

        &::before {
          position: absolute;
          content: '';
          bottom: 0;
          left: -8.1875em;
          width: 28.5em;
          height: 8.4375em;
          background: url(@public/images/header/slider-before-mobile.svg) no-repeat;
          z-index: 1;

          @include media-tablet {
            display: none; } }

        &::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: -6.125em;
          width: 28.125em;
          height: 8.25em;
          background: url(@public/images/header/slider-after-mobile.svg) no-repeat;
          z-index: 2;

          @include media-tablet {
            display: none; } } }

      &-slider {
        position: relative;
        display: none;
        height: 100%;
        background: url(@public/images/header/preview.jpg) no-repeat center center;
        background-size: cover;

        @include media-desktop {
          display: block; }

        .swiper-container {
          height: 100%; } }

      &-slide {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center; } } } } }
