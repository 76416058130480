.position {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  background: $grey-f0;
  color: $color-black;

  @include media-tablet {
    padding-bottom: 4em; }

  @include media-tablet-h {
    padding-top: 2em;
    padding-bottom: 5em; }

  @include media-desktop {
    padding-bottom: 2.1875em; }

  @include media-desktop-1600 {
    padding-bottom: 5em; }

  &__main {
    display: flex;
    flex-direction: column;
    row-gap: 6em;
    border-radius: 1.5em;
    background: #fff;
    overflow: hidden;

    @include media-tablet {
      row-gap: 7.4375em; }

    @include media-tablet-h {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      column-gap: 8.125em;
      height: 32.25em;
      padding: 2em; }

    @include media-desktop {
      column-gap: 5.625em;
      height: 37.75em;
      padding: 2.5em; }

    @include media-desktop-1600 {
      column-gap: 12.5625em;
      height: 34.375em; } }

  &__left {
    padding: 1em 1em 0;

    @include media-tablet {
      padding: 1.5em 1.5em 0; }

    @include media-tablet-h {
      padding: 0; } }

  &__title {
    @include h24;
    margin-bottom: 1.5rem;
    text-transform: uppercase;

    @include media-tablet {
      @include h36; }

    @include media-tablet-h {
      width: 18.4375rem; }

    @include media-desktop {
      @include h48;
      width: 28rem; }

    @include media-desktop-1600 {
      width: 100%;
      margin-bottom: 2rem; } }

  &__desc {
    @include media-tablet-h {
      max-width: 28.5625rem; }

    @include media-desktop {
      max-width: 34.6875rem; }
    p {
      @include p14;
      margin: 0 0 1rem;

      @include media-tablet {
        @include p18; }

      @include media-tablet-h {
        @include fs18; }

      @include media-desktop {
        @include fs24; }

      @include media-desktop-1600 {
        margin-bottom: 1.5rem; } }

    ul {
      @include p14;
      margin: 0;
      padding-left: 1.375rem;

      @include media-tablet {
        @include p16; }

      @include media-desktop {
        @include p18; }

      li:not(:last-child) {
        margin-bottom: 0.5rem;

        @include media-desktop {
          margin-bottom: 0.75rem; } } } }

  &__right {
    width: 100%;

    @include media-tablet-h {
      flex: 0 0 18.0625em;
      height: 100%; }

    @include media-desktop-1440 {
      flex-basis: 24.5625em; } }

  &__join {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
    max-width: 20.625em;
    min-height: 10.875em;
    padding: 0 1em 2em;

    @include media-tablet {
      max-width: 30.5625em;
      padding: 0 1.5em 2.9375em; }

    @include media-tablet-h {
      justify-content: center;
      height: 100%;
      padding: 0; }

    &-text {
      @include fs14;
      position: relative;
      color: #fff;
      font-weight: 700;
      z-index: 10;

      @include media-tablet {
        @include fs18; }

      p {
        @include reset;

        &:not(:last-child) {
          margin: 0 0 1rem; } } }

    .btn {
      position: relative;
      width: 100%;
      z-index: 10;

      @include media-tablet {
        width: 15.1875rem; } }

    &::before {
      content: '';
      position: absolute;
      bottom: 5em;
      left: -14rem;
      width: 39.9375rem;
      height: 15.8125rem;
      background: url(@public/images/position/join-before-mobile.svg) no-repeat;
      z-index: 1;

      @include media-tablet {
        left: 13.5rem;
        bottom: 0;
        width: 34.125rem;
        height: 10.5625rem;
        background: url(@public/images/position/join-before-tablet.svg) no-repeat; }

      @include media-tablet-h {
        top: 16.25rem;
        left: auto;
        right: -9.625rem;
        width: 42.625rem;
        height: 42.625rem;
        background: url(@public/images/position/join-before-tablet-h.svg) no-repeat; }

      @include media-desktop {
        top: 17.375rem; }

      @include media-desktop-1440 {
        right: -4rem; }

      @include media-desktop-1600 {
        top: 14.5625rem; } }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -23rem;
      width: 50.625rem;
      height: 20.5rem;
      background: url(@public/images/position/join-after-mobile.svg) no-repeat;
      background-size: cover;
      z-index: 2;

      @include media-tablet {
        left: -24rem;
        width: 59.3125rem;
        height: 20.6875rem;
        background: url(@public/images/position/join-after-tablet.svg) no-repeat;
        background-size: cover; }

      @include media-tablet-h {
        top: -9.5rem;
        left: auto;
        right: -28.75rem;
        width: 52.6875rem;
        height: 49.75rem;
        background: url(@public/images/position/join-after-tablet-h.svg) no-repeat; }

      @include media-desktop {
        right: -28rem; }

      @include media-desktop-1440 {
        right: -23rem; }

      @include media-desktop-1600 {
        top: -11.875rem; } } } }
