.stand-slider {
  display: grid;
  row-gap: 2.5em;
  column-gap: 1.5em;
  grid-template-areas: "title counter" "swiper swiper";
  grid-template-columns: 1fr auto;

  overflow: hidden;

  &__title {
    @include h24;

    grid-area: title;

    @include media-tablet {
      @include h36; } }

  &__counter {
    @include fs18;

    grid-area: counter;
    display: flex;
    align-self: center;

    font-weight: 800;

    @include media-tablet {
      @include h24; }

    &-total {
      color: $grey-e5;
      white-space: pre-wrap; } }

  &__slider {
    grid-area: swiper;
    width: 100%; }

  .swiper-button-prev,
  .swiper-button-next {
    width: 1.5em;
    height: 3.5em;

    background-color: #ffffff;

    @include media-tablet {
      width: 2.25em; }

    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      font-size: 0;

      background-image: url("../images/slider-arrow.svg");
      background-repeat: no-repeat;
      background-position: center; } }

  .swiper-button-prev {
    left: 0;

    // border-top-right-radius: 0.25em
    // border-bottom-right-radius: 0.25em
    // border-top-right-radius: 100%
    // border-bottom-right-radius: 100%
    // border-top-left-radius: 100%
    // border-bottom-left-radius: 100%
    // width:
    border-radius: 0 33px 33px 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 56px; }

  .swiper-button-next {
    right: 0;

    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;

    border-radius: 33px 0 0 33px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 56px;

    &::after {
      transform: rotate(180deg); } }

  .swiper-container {
    border-radius: 0.25em;
    overflow: hidden; }

  .swiper-slide {
    position: relative;

    height: 14.5em;

    overflow: hidden;

    @include media-tablet {
      height: 22.5em; }

    @include media-tablet-h {
      height: 30.375em; }

    @include media-desktop {
      height: 25em; }

    @include media-desktop-1440 {
      height: 28.75em; }

    @include media-desktop-1600 {
      height: 32.75em; }

    .stand-slider__img {
      position: relative;
      z-index: 10;

      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center; }

    .stand-slider__bg {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center;
      filter: blur(10px); }

    iframe {
      width: 100%;
      height: 100%; } } }
